import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../components/modules/404/404.scss';
import Button from '../components/Button';
import Separator from '../components/Separator/Separator';

const NotFoundPage = ({ data, location }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const siteTitle = data.site.siteMetadata.title;

  // Note: Workaround to remove flickering on dynamic pages
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className="NotFound">
        <main className="section-404">
          <section id="hero">
            <div className="floating-404">404</div>

            <div className="left">
              <div className="title">OOOPS!</div>
              <div className="subtitle">That&apos;s out of tune</div>

              <div className="guitar" />

              <div className="text">
                You navigated yourself to a page that is non-existing. If you
                think this is our mistake, please let us know so that we can get
                back in tune.
              </div>

              <div className="action">
                <Link to="/">
                  <Button
                    className="action-button"
                    type="button"
                    design="simple"
                    uppercase
                    text="Get me out of this mess"
                  />
                </Link>
              </div>
            </div>

            <div className="right" />
          </section>
        </main>
        <Separator id="separator-404" color="#6DB0D1" shape="squares" />
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
